import { ADD_FORM_CONFIG, DELETE_FORM_CONFIG } from '../actions/types'

const initialState = {
  formConfig: null,
}

const formConfigReducer = function (state = initialState, action) {
  switch (action.type) {
    case ADD_FORM_CONFIG:
      return {
        ...state,
        formConfig: action.formConfig,
      }
    case DELETE_FORM_CONFIG:
      return {
        ...state,
        formConfig: null,
      }
    default:
      return state
  }
}
export default formConfigReducer
