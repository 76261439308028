import { SET_ORDER, DELETE_ORDER } from '../actions/types'

const initialState = {
  orders_id: null,
  name: null,
  vat: null,
  price: null,
  size: null,
  file_requirement: null,
  size_approximate: null,
  without_files: null,
  auto_status: null,
  job_requirement: null,
  payments_condition: null,
  price_calculation_type: null,
  clients_billings_id: null,
  currencies_id: null,
  projects_id: null,
  clients_id: null,
  deposit: null,
  OrdersResponsibleEmployees: [],
  import_to_google: 1,
}

let setAll = (obj, val) => {
  for (let key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key] = []
    } else {
      obj[key] = val
    }
  }

  return obj
}

const orderReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_ORDER:
      return {
        ...state,
        [action.key]: action.value,
      }
    case DELETE_ORDER:
      const newState = setAll(state, null)
      return {
        ...newState,
      }
    default:
      return state
  }
}
export default orderReducer
