import { combineReducers } from 'redux'
import authReducer from './authReducer'
import initReducer from './initReducer'
import apiResponsesReducer from './apiResponsesReducer'
import images from './images'
import packages from './packages'
import formConfig from './formConfig'
import services from './services'
import order from './order'
import table from './table'
import form from './form'
import filters from './filters'

export default combineReducers({
  auth: authReducer,
  init: initReducer,
  responses: apiResponsesReducer,
  images,
  packages,
  formConfig,
  services,
  order,
  table,
  form,
  filters,
})
