import {
  CLEAR_TABLE,
  SET_CHECKED_TABLE,
  SET_CHECKED_TABLE_ALL,
  SET_INPUT_DATA,
} from '../actions/types'

const initialState = {
  inputData: {},
}

const tableReducer = function (state = initialState, action) {
  const key = action.key

  let newArr = []
  if (Array.isArray(state[key])) {
    newArr = [...state[key]]
  }

  const idx = newArr.indexOf(action.id)
  const hasId = idx >= 0

  switch (action.type) {
    case SET_CHECKED_TABLE:
      if (idx >= 0) {
        newArr.splice(idx, 1)
      } else {
        newArr.push(action.id)
      }

      let newState = {
        ...state,
        [`${key}_id`]: !hasId ? action.id : null,
        [key]: newArr,
      }
      if (action.data) {
        let keyData = key + '_data'
        let dataArr = []
        if (Array.isArray(state[keyData])) {
          dataArr = [...state[keyData]]
        }
        const idx = dataArr.findIndex((item) => item.id === action.data.id)

        if (idx >= 0) {
          dataArr.splice(idx, 1)
        } else {
          dataArr.push(action.data)
        }
        newState[keyData] = dataArr
      }

      return newState
    case SET_CHECKED_TABLE_ALL:
      let newChecked = state[key]?.length ? [...state[key]] : []
      if (action.checked) {
        newChecked = [...newChecked, ...action.arr]
      } else {
        newChecked = newChecked.filter((item) => {
          return !action.arr.includes(item)
        })
      }
      return {
        ...state,
        [key]: newChecked,
      }
    case SET_INPUT_DATA:
      return {
        ...state,
        inputData: action.payload,
      }
    case CLEAR_TABLE:
      return {}

    default:
      return state
  }
}
export default tableReducer
