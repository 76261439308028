import { uuidv4 } from '../../functions/functions'
import {
  ADD_ORDER_SERVICES,
  CLEAR_ORDER_SERVICES,
  COPY_ORDER_SERVICE,
  DELETE_ORDER,
  DELETE_ORDER_SERVICES,
  SET_ORDER_SERVICES,
} from '../actions/types'

const initialState = {
  services: [],
}

const servicesReducer = function (state = initialState, action) {
  let services = [...state.services]
  switch (action.type) {
    case ADD_ORDER_SERVICES:
      let connected = services.find((item) => item.checked)

      if (connected) {
        services = services.map((item) => {
          if (item.checked) {
            item.visible = false
          }
          return item
        })
      }
      services.push({
        uuid: uuidv4(),
        services_id: action.id,
        name: action.name,
        requirements_id: [],
        auto_status: 1,
        connected: connected,
        visible: !services.length || connected,
        discount_type: '%',
      })
      return {
        ...state,
        services,
      }
    case DELETE_ORDER_SERVICES:
      services.splice(action.idx, 1)

      return {
        ...state,
        services,
      }
    case CLEAR_ORDER_SERVICES:
      return {
        ...state,
        services: [],
      }
    case SET_ORDER_SERVICES:
      let newServices = Object.assign(services[action.idx], action.data)

      newServices = {
        ...newServices,
      }
      services.splice(action.idx, 1, newServices)
      return {
        ...state,
        services,
      }
    case COPY_ORDER_SERVICE:
      const copy = {
        ...services[action.idx],
        uuid: uuidv4(),
        id: null,
      }
      services.push(copy)

      return {
        ...state,
        services,
      }
    case DELETE_ORDER:
      return {
        ...state,
        services: [
          /* cloneDeep(defaultService) */
        ],
      }
    default:
      return state
  }
}
export default servicesReducer
