const urlDetector = {
  name: 'urlDetector',

  lookup() {
    const path = window.location.pathname.split('/').filter((item) => item)[0]
    if (path && path.length === 2) {
      return path
    }

    // options -> are passed in options
    return false
  },
}
export default urlDetector
