;(function () {
  function decimalAdjust(type, value, exp) {
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value)
    }
    value = +value
    exp = +exp
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN
    }
    value = value.toString().split('e')
    value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)))
    value = value.toString().split('e')
    return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp))
  }

  if (!Math.round10) {
    Math.round10 = function (value, exp) {
      return decimalAdjust('round', value, exp)
    }
  }
  if (!Math.floor10) {
    Math.floor10 = function (value, exp) {
      return decimalAdjust('floor', value, exp)
    }
  }
  if (!Math.ceil10) {
    Math.ceil10 = function (value, exp) {
      return decimalAdjust('ceil', value, exp)
    }
  }
})()

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export const lowerCaseFirstLetter = (string) => {
  return string.charAt(0).toLocaleLowerCase() + string.slice(1)
}

export const getLabelFromString = (string) => {
  if (typeof string !== 'string') {
    return string
  }

  let array = string.split('_')
  array[0] = capitalizeFirstLetter(array[0])
  return array.join(' ')
}

export const removeFromArrayByValue = (array, item) => {
  let index = array.indexOf(item)
  if (index > -1) array.splice(index, 1)
  return array
}

export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}
export const daysCalculate = (start, end) => {
  const diff = end.getTime() - start.getTime()
  return Math.ceil(diff / (1000 * 3600 * 24)) - 1
}

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  )
}

export const groupBy = (xs, key, push = true) => {
  return xs.reduce(function (rv, x) {
    if (push) {
      ;(rv[x[key]] = rv[x[key]] || []).push(x)
    } else {
      rv[x[key]] = x
    }
    return rv
  }, {})
}

export const round10 = (value) => {
  const afterDot = `${value.toFixed(2)}`.split('.')[1]
  const values = afterDot.split('')
  const shouldRound = +values[1] === 9 || +values[1] === 1

  if (shouldRound) {
    return Math.round10(value, -1)
  }

  return value
}

export const cutString = (string, length) => {
  if (string.length > length) {
    return string.substring(0, length) + '...'
  }
  return string
}
