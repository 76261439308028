import { ADD_IMAGES, DELETE_IMAGES } from '../actions/types'

const initialState = {
  field: null,
  cropped: null,
  filename: null,
}

const imagesReducer = function (state = initialState, action) {
  switch (action.type) {
    case ADD_IMAGES:
      return {
        ...state,
        field: action.field,
        cropped: action.cropped,
        filename: action.filename,
      }
    case DELETE_IMAGES:
      return {
        ...state,
        field: null,
        cropped: null,
        filename: null,
      }
    default:
      return state
  }
}
export default imagesReducer
