import { CLEAR_FORM, SET_FORM_DATA } from '../actions/types'

const initialState = {
  formData: {},
}

const formReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_FORM_DATA:
      let formData = { ...state.formData, ...{ [action.name]: action.value } }
      return {
        ...state,
        formData: formData,
      }
    case CLEAR_FORM:
      return {
        formData: {},
      }

    default:
      return state
  }
}
export default formReducer
